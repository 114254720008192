import Users from "./pages/Users/Users";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import AccountSetting from "./pages/v2/AccountSetting";
import UpdateUser from "./pages/Users/UpdateUser";
import Product from "./pages/Product/Product";
import ProductCreate from "./pages/Product/ProductCreate";
import ProductUpdate from "./pages/Product/ProductUpdate";
import QuizOptions from "./pages/Quiz/QuizOptions";
import Quiz from "./pages/Quiz/Quiz";
import QuizCreate from "./pages/Quiz/QuizCreate";
import QuizUpdate from "./pages/Quiz/QuizUpdate";
import QuizResult from "./pages/Quiz/QuizResult";
import Blog from "./pages/Blog/Blog";
import BlogCreate from "./pages/Blog/BlogCreate";
import BlogUpdate from "./pages/Blog/BlogUpdate";
import BlogCategory from "./pages/BlogCategory/BlogCategory";

const pagesList = [
  // with parent
  {
    route: "/dashboard/product",
    component: Product,
    childs: [
      {
        route: "/dashboard/product/create",
        component: ProductCreate,
        access: "create",
      },
      {
        route: "/dashboard/product/update/:id",
        component: ProductUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/blog",
    component: Blog,
    childs: [
      {
        route: "/dashboard/blog/create",
        component: BlogCreate,
        access: "create",
      },
      {
        route: "/dashboard/blog/update/:id",
        component: BlogUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/users",
    component: Users,
    childs: [
      {
        route: "/dashboard/users/update/:id",
        component: UpdateUser,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/quiz",
    component: Quiz,
    childs: [
      {
        route: "/dashboard/quiz/create",
        component: QuizCreate,
        access: "create",
      },
      {
        route: "/dashboard/quiz/update/:id",
        component: QuizUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },

  // new
  {
    route: "/dashboard/quiz-options",
    component: QuizOptions,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/blog-categories",
    component: BlogCategory,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/quiz-result",
    component: QuizResult,
    childs: [],
    hasChild: false,
  },

  // dashboard/details is hidden , i hide in login file
  {
    route: "/dashboard/account",
    component: AccountSetting,
    childs: [],
    hasChild: false,
  },
];

export default pagesList;
