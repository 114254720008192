// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

// config

// api related
import classNames from "classnames";
import Api from "../../api/Api";
import { useGetRole } from "../../hook/role.hook";

const UpdateUser = ({ permissions }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: roleOptions } = useGetRole({ filter_super_admin: true });
  const { isLoading: loadingUser } = useQuery(["user", id], async () => await getUserDetails(), { defaultValue: [] });

  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/user/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/users");
        toast.success("User Updated", { duration: 5000 });
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    crateMutate({ ...data, id: data.id });
  };

  const getUserDetails = async () => {
    const response = await Api().get(`/user/detail/${id}`);
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    reset(response.data.data);
    return response.data.data;
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="col-12">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update User
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0" }} className="card">
            <Toolbar className="mb-4" left={leftToolbar} />
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="name">Name: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="name"
                  name="name"
                  render={({ field }) => <InputText placeholder="input name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={formState.errors.name && "p-invalid"} />}
                />
                {formState.errors.name && (
                  <small id="name" className="p-error block pt-1">
                    field required
                  </small>
                )}
              </div>

              <div className="field col-12">
                <label htmlFor="email">E-Mail: </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="email"
                  name="email"
                  render={({ field }) => <InputText placeholder="input email" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="email" type="text" className={formState.errors.name && "p-invalid"} />}
                />
                {formState.errors.name && (
                  <small id="name" className="p-error block pt-1">
                    field required
                  </small>
                )}
              </div>
              <div className="field col-12">
                <label htmlFor="phone_number">No Hp : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  id="phone_number"
                  name="phone_number"
                  render={({ field }) => (
                    <InputText
                      placeholder="input phone number"
                      value={field.value}
                      onBlur={field.onBlur}
                      ref={field.ref}
                      onChange={(e) => field.onChange(e)}
                      id="phone_number"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className={formState.errors.phone_number && "p-invalid"}
                    />
                  )}
                />
                {formState.errors.phone_number && (
                  <small id="phone_number" className="p-error block pt-1">
                    Field Required
                  </small>
                )}
              </div>
              <div className="field col-12 ">
                <label htmlFor="choose-kurir">Role :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="role_id"
                  render={({ field }) => (
                    <Dropdown
                      inputRef={field.ref}
                      optionLabel="name"
                      optionValue="id"
                      disabled={!roleOptions?.length}
                      value={field.value}
                      onBlur={field.onBlur}
                      options={roleOptions}
                      className={classNames({
                        "p-invalid": formState.errors.role_id,
                      })}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      placeholder="Pilih Role"
                    />
                  )}
                />
                {formState.errors.role_id && (
                  <small id="choose-kurir" className="p-error block pt-1">
                    Field Required
                  </small>
                )}
              </div>

              <div className="w-full flex justify-content-center mt-4">
                <div className="col-6 md:col-3 xl:col-2">
                  <Button disabled={loadingUser} label="Save" loading={createLoading} className=" p-button-primary mr-4" />
                </div>
                <div className="col-6 md:col-3 xl:col-2">
                  <Link to="/dashboard/users">
                    <Button type="button" label="Back" className=" p-button-secondary" />
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateUser, comparisonFn);
