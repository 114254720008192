import { Jodit } from "jodit-react";

const JodithJournalConfig = {
  readonly: false,
  askBeforePasteHTML: false,
  defaultActionOnPaste: "insert_only_text",
  placeholder: "Start typings...",
  defaultFontName: "",
  cleanHTML: {
    fillEmptyParagraph: true,
  },
  height: 280,
  style: {
    fontSize: "14px",
  },
  buttons: [...Jodit.defaultOptions.buttons],
  removeButtons: ["font", "listitem", "eraser", "about", "print", "file", "video", "subscript", "superscript", "source", "table", "symbols", "paste", "selectall", "cut", "copy", "copyformat", "classSpan", "spellcheck", "preview"],
  controls: {
    fontsize: {
      list: {
        14: "14",
        16: "16",
        18: "18",
        20: "20",
      },
    },
    color: {
      list: {
        red: "#ada",
      },
    },
    font: {
      list: {
        Karla: "Karla",
        "Kiwi Maru": "Kiwi Maru",
      },
    },
  },
  colors: {
    greyscale: ["#000000", "#444444", "#434343", "#666666", "#999999", "#B7B7B7", "#CCCCCC", "#D9D9D9", "#EFEFEF", "#F3F3F3", "#FFFFFF"],
    palette: ["#3c4536", "#006F79"],
  },
  uploader: { insertImageAsBase64URI: true },
};

export default JodithJournalConfig;
