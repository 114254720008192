// hooks
import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import toast from "react-hot-toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";

// utils
import basicJodithConfig from "../../config/JodithJournalConfig";

// api related
import Api from "../../api/Api";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import JoditEditor from "jodit-react";
import ReactImageUploading from "react-images-uploading";
import { Image } from "primereact/image";

const typeOption = [
  { label: "BASIC", value: 1, image: "/images/type-1.png" },
  { label: "ALTERNATIVE", value: 2, image: "/images/type-2.png" },
  { label: "TUTORIAL", value: 3, image: "/images/type-3.png" },
];

const BlogCreate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const { control, handleSubmit, getValues, watch } = useForm();
  const { fields, append, remove } = useFieldArray({ name: "contents", control: control });
  const [blogId, setBlogId] = useState(null);
  const oneMBInBytes = 1048576;
  const imageUploadMaxSize = 1 * oneMBInBytes;

  const typeWatch = watch("layout_type");

  // query
  const { isLoading: createLoading, mutate: createBlog } = useMutation(async (data) => await Api().post(blogId ? "/blog/edit" : "/blog", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setBlogId(response.data.data.id);
        createContent(response.data.data.id, getValues("contents"));
      } else {
        toast.error(response.data.err, { duration: 5000 });
      }
    },
  });

  const { data: categories } = useQuery(
    "blog-category",
    async () => {
      try {
        const res = await Api().get("/blog-category");

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
        return [];
      }
    },
    { initialData: [] }
  );
  const { data: blogs } = useQuery(
    "blog",
    async () => {
      try {
        const res = await Api().get("/blog?page=1&limit=999999", {
          headers: {
            organization: process.env.REACT_APP_ORGANIZATION_ID,
          },
        });

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
        return [];
      }
    },
    { initialData: [] }
  );

  // functions
  const BooleanToString = (value) => {
    return value ? 1 : 0;
  };

  const onSubmit = async (data) => {
    try {
      const formDataBlog = new FormData();
      // require images
      if (!data?.files?.length) {
        throw new Error("Banner Desktop Required");
      } else {
        formDataBlog.append("files", data.files[0].file);
      }

      if (!data?.files_mobile?.length) {
        throw new Error("Banner Mobile Required");
      } else {
        formDataBlog.append("files_mobile", data.files_mobile[0].file);
      }

      if (!data?.files_thumbnail?.length) {
        throw new Error("Thumbnail Required");
      } else {
        formDataBlog.append("files_thumbnail", data.files_thumbnail[0].file);
      }

      // blog data
      formDataBlog.append("meta_title", data.meta_title);
      formDataBlog.append("meta_description", data.meta_description);
      formDataBlog.append("layout_type", data.layout_type);
      formDataBlog.append("title", data.title);
      formDataBlog.append("short_description", data.short_description);
      formDataBlog.append("body", data.body);
      if (data.related.length) {
        formDataBlog.append("related", data.related.join(","));
      }
      formDataBlog.append("active_status", BooleanToString(data.active_status));
      formDataBlog.append("order", data.order);
      formDataBlog.append("category_id", data.category_id);

      createBlog(formDataBlog);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createBlogContent = async (data) => {
    try {
      const formData = new FormData();
      formData.append("blog_id", data.blog_id);
      formData.append("title", data.title);
      formData.append("order", data.order);
      formData.append("body", data.body);

      if (blogId) {
        formData.append("id", blogId);
      }

      if (data.files?.length) {
        for (let i = 0; i < data.files.length; i++) {
          formData.append("files", data.files[i].file);
        }
      }

      const res = await Api().post("/blog-content", formData);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return true;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };
  const onImageUploadError = (errors) => {
    if (errors?.maxFileSize) {
      toast.error("Max file size : " + imageUploadMaxSize / oneMBInBytes + "MB");
    }

    if (errors?.acceptType) {
      toast.error("Invalid file type. Only jpg, png, jpeg, gif, webp are allowed.");
    }

    if (errors.maxNumber) {
      toast.error("Max number of images exceeded");
    }
  };

  const createContent = async (blogID, contents) => {
    try {
      setIsLoading(true);
      contents = contents.map((content) => ({ ...content, blog_id: blogID }));

      for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        const result = await createBlogContent(content);
        if (!result) {
          throw new Error("Failed to create blog content");
        }
      }

      navigate("/dashboard/blog");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Blog
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12">
          <label htmlFor="text">Title</label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="title"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <InputText className="w-full" placeholder="Input title" value={field.value} onChange={field.onChange} id="text" type="text" />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="text">Thumbnail Description</label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="short_description"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <InputTextarea placeholder="Input description" value={field.value} onChange={field.onChange} id="text" type="text" className="w-full" rows={5} />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="text">Meta Title</label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="meta_title"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <InputText className="w-full" placeholder="Input meta title" value={field.value} onChange={field.onChange} id="text" type="text" />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="text">Meta Description</label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="meta_description"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <InputTextarea placeholder="Input meta description" value={field.value} onChange={field.onChange} id="text" type="text" className="w-full" rows={5} />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        <div className="field col-12">
          <label htmlFor="text">Layout :</label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="layout_type"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <div className="flex flex-wrap gap-3" style={{ gap: "20px" }}>
                    {typeOption.map((type) => (
                      <div className="">
                        <div className="flex gap-4 align-items-center" style={{ gap: "8px", alignItems: "center" }}>
                          <RadioButton inputId={type.value} name={type.label} value={type.value} onChange={field.onChange} checked={field.value === type.value} />
                          <label className="cursor-pointer" htmlFor={type.value}>
                            <p>{type.label}</p>
                          </label>
                        </div>
                        <label htmlFor={type.value} className="ml-2">
                          <div className="flex cursor-pointer">
                            <Image width="140" src={type.image} alt={type.label} />
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="text">Category :</label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="category_id"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <Dropdown id="type" value={field.value} className="w-full" onChange={(e) => field.onChange(e)} options={categories.map((val) => ({ label: val.name, value: val.id }))} placeholder="Select Option" />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="text">Related :</label>
          <div className="w-full">
            <Controller
              defaultValue={[]}
              control={control}
              name="related"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <MultiSelect id="type" value={field.value} className="w-full" onChange={(e) => field.onChange(e)} options={blogs.map((val) => ({ label: val.title, value: val.id }))} placeholder="Select Option" />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="order">Order :</label>
          <div className="w-full">
            <Controller defaultValue={1} control={control} name="order" render={({ field }) => <InputNumber min={0} value={field.value} onValueChange={(e) => field.onChange(e)} className="w-full" showButtons mode="decimal" placeholder="0" />} />
          </div>
        </div>

        {typeWatch === 3 ? (
          <div className="field col-12">
            <label htmlFor="section1">Body : </label>
            <Controller
              control={control}
              name="body"
              render={({ field, fieldState }) => (
                <div className="w-full">
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                  {fieldState.error?.message && (
                    <small id="section1" className="p-error block pt-1">
                      filed required
                    </small>
                  )}
                </div>
              )}
            />
          </div>
        ) : null}
        <Controller
          name="files_thumbnail"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="field col-12">
              <label htmlFor="">Thumbnail (Max 1) | Size (800 x 800) or (aspect ratio 1:1)</label>
              <ReactImageUploading style={{ width: "100%" }} maxNumber={1} maxFileSize={imageUploadMaxSize} onError={onImageUploadError} value={value} onChange={onChange} acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                  <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                    <div className="flex justify-content-center w-full">
                      <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                    </div>

                    {imageList.length ? (
                      <div className="grid mt-4 w-full">
                        {imageList.map((image, index) => (
                          <div key={index} className="col-4 lg:col-2 relative">
                            <img src={image.dataURL} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                            <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                            <div className="flex" style={{ gap: "10px" }}>
                              <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                )}
              </ReactImageUploading>
            </div>
          )}
        />
        <Controller
          name="files"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="field col-12">
              <label htmlFor="">Banner (Desktop : Max 1) | Size (1900 x 810) | For Basic Layout (800 x 800)</label>
              <ReactImageUploading style={{ width: "100%" }} maxNumber={1} maxFileSize={imageUploadMaxSize} onError={onImageUploadError} value={value} onChange={onChange} acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                  <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                    <div className="flex justify-content-center w-full">
                      <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                    </div>

                    {imageList.length ? (
                      <div className="grid mt-4 w-full">
                        {imageList.map((image, index) => (
                          <div key={index} className="col-4 lg:col-2 relative">
                            <img src={image.dataURL} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                            <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                            <div className="flex" style={{ gap: "10px" }}>
                              <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                )}
              </ReactImageUploading>
            </div>
          )}
        />
        <Controller
          name="files_mobile"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="field col-12">
              <label htmlFor="">Banner (Mobile : Max 1) | Size (800 x 1200) | For Basic & Alternative Layout (800 x 800)</label>
              <ReactImageUploading style={{ width: "100%" }} maxNumber={1} maxFileSize={imageUploadMaxSize} onError={onImageUploadError} value={value} onChange={onChange} acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                  <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                    <div className="flex justify-content-center w-full">
                      <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                    </div>

                    {imageList.length ? (
                      <div className="grid mt-4 w-full">
                        {imageList.map((image, index) => (
                          <div key={index} className="col-4 lg:col-2 relative">
                            <img src={image.dataURL} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                            <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                            <div className="flex" style={{ gap: "10px" }}>
                              <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                )}
              </ReactImageUploading>
            </div>
          )}
        />

        <section className="field col-12">
          <label>Content :</label>
          {fields.map((field, index) => (
            <section key={field.id} className="field col-12 p-2" style={{ border: "1px solid black" }}>
              <div className="field justify-content-end flex">
                <Button className="p-button-danger" type="button" onClick={() => remove(index)} label="Remove" />
              </div>
              <div className="field col-12">
                <label htmlFor="text">Title</label>
                <div className="w-full">
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name={`contents.${index}.title`}
                    render={({ field, fieldState }) => (
                      <div className="w-full">
                        <InputText className="w-full" placeholder="Input title" value={field.value} onChange={field.onChange} id="text" type="text" />
                        {fieldState.error?.message && (
                          <small id="section1" className="p-error block pt-1">
                            filed required
                          </small>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="field col-12">
                <label htmlFor="section1">Body : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={`contents.${index}.body`}
                  render={({ field, fieldState }) => (
                    <div className="w-full">
                      <JoditEditor
                        ref={field.ref}
                        value={field.value || ""}
                        config={basicJodithConfig}
                        tabIndex={1}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                      {fieldState.error?.message && (
                        <small id="section1" className="p-error block pt-1">
                          filed required
                        </small>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="order">Order :</label>
                <div className="w-full">
                  <Controller defaultValue={index + 1} control={control} name={`contents.${index}.order`} render={({ field }) => <InputNumber min={0} value={field.value} onValueChange={(e) => field.onChange(e)} className="w-full" showButtons mode="decimal" placeholder="0" />} />
                </div>
              </div>
              <Controller
                name={`contents.${index}.files`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="field col-12">
                    <label htmlFor="">Images (max 5) | Size (1200 x 800)</label>
                    <ReactImageUploading
                      style={{ width: "100%" }}
                      maxNumber={5}
                      multiple
                      maxFileSize={imageUploadMaxSize}
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      onError={onImageUploadError}
                      acceptType={["jpg", "png", "jpeg", "gif", "webp"]}
                    >
                      {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                        <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                          <div className="flex justify-content-center w-full">
                            <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                          </div>

                          {imageList.length ? (
                            <div className="grid mt-4 w-full">
                              {imageList.map((image, index) => (
                                <div key={index} className="col-4 lg:col-2 relative">
                                  <img src={image.dataURL} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                                  <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                                  <div className="flex" style={{ gap: "10px" }}>
                                    <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                )}
              />
            </section>
          ))}
          <div className="w-full">
            <Button onClick={() => append({})} label="Add Content" type="button" />
          </div>
        </section>

        <div className="field col-12">
          <label className="block" htmlFor="active_status">
            Publish
          </label>
          <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
        </div>

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading || isLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/blog">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(BlogCreate, comparisonFn);
