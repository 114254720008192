import Api from "./Api";

export default {
  Get() {
    return Api().get("/role?page=1&limit=999999");
  },
  Details(id) {
    return Api().get(`/role/${id}`);
  },
  Update(data) {
    return Api().post(`/role/edit`, data);
  },
  Add(data) {
    return Api().post(`/role`, data);
  },
  Delete(data) {
    return Api().delete(`/role`, { data: data });
  },
};
