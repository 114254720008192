// hooks
import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import useUserStore from "../../stores/userStore";

// conponents
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { InputTextarea } from "primereact/inputtextarea";

// api related
import RolesApi from "../../api/RolesApi";
import Cookies from "js-cookie";
import Api from "../../api/Api";

const UpdateRole = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userStore = useUserStore((state) => state);

  // hooks
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: "permissions",
  });

  // api calling
  const { isError: isRoleDetailsError } = useQuery(["role", id], async () => getRoleDetails());

  // query
  const { isLoading: createLoading, mutate: UpdateMutate } = useMutation(async (data) => await RolesApi.Update(data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        if (userStore.user.role === +id) {
          toast.success("Please Re-Login");
          userStore.resetUser();
          Cookies.remove("token");
          navigate("/login");
        } else {
          navigate("/dashboard/role");
        }
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    delete data._id;
    delete data.created_at;

    let permissions = [];

    for (let i = 0; i < data.permissions.length; i++) {
      let current_permission = data.permissions[i].actions;

      permissions.push({
        id: current_permission.id,
        create: current_permission.create ? 1 : 0,
        delete: current_permission.delete ? 1 : 0,
        update: current_permission.update ? 1 : 0,
        view: current_permission.view ? 1 : 0,
        name: data.permissions[i].name,
      });
    }

    data.permissions = permissions;

    UpdateMutate({ ...data, id: id });
  };

  const getRoleDetails = async () => {
    const response = await Api().get("/role/detail/" + id);

    if (response.data.status !== 200) {
      throw new Error(response.data.message);
    }

    let userPermissions = [];

    for (let i = 0; i < response.data.data.permissions.length; i++) {
      userPermissions.push({ name: response.data.data.permissions[i].page.name, actions: { ...response.data.data.permissions[i] } });
    }

    let user_permissions_mapping = [];
    for (let i = 0; i < userPermissions.length; i++) {
      let current_permission = userPermissions[i].actions;

      user_permissions_mapping.push({
        name: userPermissions[i].name,
        actions: {
          id: current_permission.id,
          create: current_permission.create === 1 ? true : false,
          delete: current_permission.delete === 1 ? true : false,
          update: current_permission.update === 1 ? true : false,
          view: current_permission.view === 1 ? true : false,
        },
      });
    }

    delete response.data.data.permissions;
    reset(response.data.data);
    append(user_permissions_mapping);

    return response.data.data;
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Role
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/role">
          <Button label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  // lifecycle

  useEffect(() => {
    if (isRoleDetailsError) {
      navigate("/dashboard/role");
    }
  }, [isRoleDetailsError, navigate]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0" }} className="card ">
          <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
          <div className="p-fluid formgrid grid ">
            <div className="field col-12 md:col-12 mt-4">
              <label htmlFor="user">Role Name :</label>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="name"
                render={({ field }) => <InputText placeholder="Input role name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={errors.name && "p-invalid"} />}
              />
              {errors.name && (
                <small id="name-help" className="p-error block">
                  Field Required
                </small>
              )}
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="user">Role Description :</label>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="description"
                render={({ field }) => <InputTextarea rows={10} placeholder="input role description" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={errors.name && "p-invalid"} />}
              />
              {errors.name && (
                <small id="name-help" className="p-error block">
                  Field Required
                </small>
              )}
            </div>
            <div className="col-12 mt-5">
              <h5 htmlFor="user">Access Permissions:</h5>
              <DataTable value={fields} responsiveLayout="scroll">
                <Column headerStyle={{ width: "30%", minWidth: "20rem" }} body={(data) => <p>{data.name}</p>} header="Page Name"></Column>
                <Column
                  headerStyle={{ width: "10%", minWidth: "8rem" }}
                  body={(data, index) => (
                    <div className="field-checkbox mr-2">
                      <Controller
                        control={control}
                        name={`permissions.${index.rowIndex}.actions.create`}
                        render={({ field }) => (
                          <Checkbox
                            inputId={`permissions.${index.rowIndex}.actions.create`}
                            onChange={(e) => {
                              field.onChange(e.checked);
                              fields[index.rowIndex].actions.view = true;
                              const currentValue = getValues();
                              reset(currentValue);
                            }}
                            name={`permissions.${index.rowIndex}.actions.create`}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  )}
                  header="Create"
                ></Column>
                <Column
                  headerStyle={{ width: "10%", minWidth: "8rem" }}
                  body={(data, index) => (
                    <div className="field-checkbox mr-2">
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`permissions.${index.rowIndex}.actions.update`}
                        render={({ field }) => (
                          <Checkbox
                            inputId={`permissions.${index.rowIndex}.actions.update`}
                            onChange={(e) => {
                              field.onChange(e.checked);
                              fields[index.rowIndex].actions.view = true;
                              const currentValue = getValues();
                              reset(currentValue);
                            }}
                            name={`permissions.${index.rowIndex}.actions.update`}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  )}
                  header="Update"
                ></Column>
                <Column
                  headerStyle={{ width: "10%", minWidth: "8rem" }}
                  body={(data, index) => (
                    <div className="field-checkbox mr-2">
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`permissions.${index.rowIndex}.actions.delete`}
                        render={({ field }) => (
                          <Checkbox
                            inputId={`permissions.${index.rowIndex}.actions.delete`}
                            onChange={(e) => {
                              field.onChange(e.checked);
                              fields[index.rowIndex].actions.view = true;
                              const currentValue = getValues();
                              reset(currentValue);
                            }}
                            name={`permissions.${index.rowIndex}.actions.delete`}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  )}
                  header="Delete"
                ></Column>
                <Column
                  headerStyle={{ width: "10%", minWidth: "8rem" }}
                  body={(data, index) => (
                    <div className="field-checkbox mr-2">
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`permissions.${index.rowIndex}.actions.view`}
                        render={({ field }) => (
                          <Checkbox
                            disabled={fields[index.rowIndex].actions.create || fields[index.rowIndex].actions.update || fields[index.rowIndex].actions.delete}
                            inputId={`permissions.${index.rowIndex}.actions.view`}
                            onChange={(e) => field.onChange(e.checked)}
                            name={`permissions.${index.rowIndex}.actions.view`}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  )}
                  header="View"
                ></Column>
              </DataTable>
            </div>
          </div>

          <div className="flex justify-content-center mt-4">
            <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
            <Link to="/dashboard/role">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateRole, comparisonFn);
