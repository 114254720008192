// hooks
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { InputNumber } from "primereact/inputnumber";

const QuizOptions = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [uploadThumb, setUploadThum] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // api calling
  const {
    isLoading,
    data: quizOptions,
    refetch,
  } = useQuery(
    "quiz-choice",
    async () => {
      try {
        const res = await Api().get("/quiz-choice");

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: [] }
  );

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("quiz-choice", data), {
    onSettled: async (response, error) => {
      try {
        if (error) throw new Error(error?.message);
        if (response.data.status !== 200) throw new Error(response?.data.message);
        refetch();
        reset({});
        setSelectedFile(null);
        setShowCreateModal(false);
        toast.success("Quiz Options Created!");
      } catch (error) {
        refetch();
        setShowCreateModal(false);
        toast.error(error.message || "Failed to create");
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/quiz-choice/edit", data), {
    onSettled: async (response, error) => {
      try {
        if (error) throw new Error(error?.message);
        if (response.data.status !== 200) throw new Error(response?.data.message);

        refetch();
        updateForm.reset({});
        setSelectedFile(null);
        setShowEditModal(false);
        toast.success("Quiz Options Updated!", { duration: 4000 });
      } catch (error) {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message);
        toast.error(error.message || "Failed to update");
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/quiz-choice", { data }), {
    onSettled: async (response, error) => {
      try {
        if (error) throw new Error(error?.message);
        if (response.data.status !== 200) throw new Error(response?.data.message);
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Quiz Options Deleted!");
      } catch (error) {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message);
        toast.error(error.message || "Failed to update");
      }
    },
  });

  // react hook
  const { reset, handleSubmit, register, control } = useForm();
  const updateForm = useForm();

  // functions
  const create = (data) => {
    const formData = new FormData();
    if (data.text) {
      formData.append("text", data.text);
    }

    formData.append("point", data.point);

    if (selectedFile) {
      formData.append("files", selectedFile);
    }
    crateMutate(formData);
  };

  const update = (data) => {
    const formData = new FormData();

    if (data.text) {
      formData.append("text", data.text);
    }

    if (selectedFile) {
      delete data.image;
      formData.append("files", selectedFile);
    } else {
      if (data.image) {
        formData.append("image", data.image);
      }
    }
    formData.append("point", data.point);

    formData.append("id", data.id);
    updateMutate(formData);
  };

  const showNewModal = () => {
    setUploadThum(null);
    reset({});
    setShowCreateModal(true);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i].id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data.id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = (data) => {
    setUploadThum(data.image);
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return <>{rowData.image ? <Image preview src={rowData.image} alt={rowData.name} className="shadow-2" width="100" /> : "-"}</>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Quiz Options</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={quizOptions}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="text" header="Text" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="created_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create Quiz Options" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="text">Text</label>
            <InputTextarea placeholder="Input text" {...register("text")} id="text" type="text" />
          </div>
          <div className="field">
            <label htmlFor="choose-kurir">Point :</label>
            <Controller rules={{ required: true }} control={control} name="point" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Quiz Options" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="text">Text</label>
            <InputTextarea placeholder="Input text" {...updateForm.register("text")} id="text" type="text" />
          </div>
          <div className="field">
            <label htmlFor="choose-kurir">Point :</label>
            <Controller rules={{ required: true }} control={updateForm.control} name="point" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Item?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(QuizOptions, comparisonFn);
