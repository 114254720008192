// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import toast from "react-hot-toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";

const ProductUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/product/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/product");
        toast.success("Product Updated!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      const res = await Api().get("/product?page=1&limit=999999");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onSubmit = (data) => {
    delete data.related_product;
    delete data.created_at;
    delete data.organization_id;

    updateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Product
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const response = await Api().get("/product/detail/" + id);
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        const details = response.data.data;
        reset(details);
      } catch (error) {
        toast.error(error);
      }
    };

    if (id) {
      getProductDetails();
    }
  }, [id, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12">
          <label htmlFor="name">Product Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="name"
              render={({ field }) => (
                <InputText
                  placeholder="Input product name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="order">Min Point : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="min_point"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.min_point }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.min_point && (
            <small id="min_point" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="order">Max Point : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="max_point"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.max_point }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.max_point && (
            <small id="max_point" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="related">Related Product : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="related"
              defaultValue={null}
              render={({ field }) => (
                <Dropdown
                  inputRef={field.ref}
                  disabled={!productOption?.length}
                  optionLabel="name"
                  optionValue="id"
                  value={field.value}
                  className="w-full"
                  onBlur={field.onBlur}
                  options={productOption}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Select product"
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/product">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ProductUpdate, comparisonFn);
