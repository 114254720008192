// hooks
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useUserStore from "../../stores/userStore";

// components
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Password } from "primereact/password";

// utils
import classNames from "classnames";
import Api from "../../api/Api";
import { InputText } from "primereact/inputtext";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Toolbar } from "primereact/toolbar";

const AccountSetting = ({ permissions }) => {
  const token = Cookies.get("token");
  const user = jwtDecode(token);
  const userStore = useUserStore((state) => state);
  // schema validation
  const changePasswordSchema = Yup.object().shape({
    current_password: Yup.string().required("Input your current password"),
    password: Yup.string().required("Input new password").min(7, "Password must be > 7"),
    confirm_password: Yup.string()
      .required("Input your new confirm password")
      .oneOf([Yup.ref("password")], "Password and confirm password is not same"),
  });
  const changePasswordResolver = { resolver: yupResolver(changePasswordSchema) };

  // React Query
  const { refetch } = useQuery(["user", user.id], () => getUserDetails(), { initialData: {}, enabled: !!user.id });
  const { isLoading: loadingChangePassword, mutate } = useMutation(async (data) => await Api().post("/user/change-password", data), {
    onSettled: (res, error) => {
      if (error) {
        return toast.error("Failed to change password");
      }

      if (res.data.status !== 200) {
        return toast.error(res.data.message);
      }

      toast.success("Password successfully changed");
      changePasswordForm.reset({});
    },
  });
  const { isLoading: loadingChangeDetails, mutate: updateUserDetails } = useMutation(async (data) => await Api().post("/user/profile", data), {
    onSettled: (res, error) => {
      if (res.data.status !== 200) {
        return toast.error(res.data.message);
      }

      toast.success("profile updated");
      changeUserProfileForm.reset({});
      refetch();
    },
  });

  // hooks
  const changePasswordForm = useForm(changePasswordResolver);
  const changeUserProfileForm = useForm();
  const [activeIndex, setActiveIndex] = useState(0);

  // functions
  const getUserDetails = async () => {
    try {
      const response = await Api().get(`user/detail/${user.id}`);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      const dataUser = response.data.data;
      let payload = {
        first_name: dataUser.name,
        last_name: dataUser.last_name,
        // alias_name: dataUser.alias_name,
        email: dataUser.email,
        phone_number: dataUser.phone_number,
        dob: dataUser?.dob,
      };
      changeUserProfileForm.reset(payload);
      userStore.setUser(response.data.data);
      return response.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const changeUserDetailHandler = async (data) => {
    updateUserDetails({ ...data, user_id: user.id });
  };

  const changePasswordHandler = async (data) => {
    mutate({ ...data, user_id: user.id });
  };

  return (
    <div className="p-fluid formgrid grid card col-12 mx-auto">
      <div className="col-12">
        <Toolbar
          className="mb-4 py-4"
          left={() => (
            <div className="">
              <h1 className="text-xl uppercase p-0 m-0">Account Setting</h1>
            </div>
          )}
        />
      </div>
      <div className="col-12">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Account Details">
            <form onSubmit={changeUserProfileForm.handleSubmit(changeUserDetailHandler)} className="p-fluid formgrid grid">
              {/* form */}
              <div className="field col-12 md:col-12">
                <label htmlFor="first_name">First Name :</label>
                <Controller
                  rules={{ required: true }}
                  control={changeUserProfileForm.control}
                  defaultValue={""}
                  name="first_name"
                  render={({ field }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": changeUserProfileForm.formState.errors.first_name,
                      })}
                      ref={field.ref}
                      id="first_name"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="input first name"
                    />
                  )}
                />
                {changeUserProfileForm.formState.errors.first_name && (
                  <small id="first_name" className="p-error block pt-1">
                    {changeUserProfileForm.formState.errors.first_name.message}
                  </small>
                )}
              </div>
              {/* <div className="field col-12 md:col-12">
                <label htmlFor="last_name">Last Name :</label>
                <Controller
                  rules={{ required: true }}
                  control={changeUserProfileForm.control}
                  defaultValue={""}
                  name="last_name"
                  render={({ field }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": changeUserProfileForm.formState.errors.last_name,
                      })}
                      ref={field.ref}
                      id="last_name"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Input last name"
                    />
                  )}
                />
                {changeUserProfileForm.formState.errors.last_name && (
                  <small id="last_name" className="p-error block pt-1">
                    {changeUserProfileForm.formState.errors.last_name.message}
                  </small>
                )}
              </div> */}

              <div className="field col-12 md:col-12">
                <label htmlFor="last_name">E-Mail :</label>
                <Controller
                  rules={{ required: true }}
                  control={changeUserProfileForm.control}
                  defaultValue={""}
                  name="email"
                  render={({ field }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": changeUserProfileForm.formState.errors.email,
                      })}
                      ref={field.ref}
                      id="email"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="input email"
                    />
                  )}
                />
                {changeUserProfileForm.formState.errors.email && (
                  <small id="email" className="p-error block pt-1">
                    {changeUserProfileForm.formState.errors.email.message}
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="last_name">No Hp :</label>
                <Controller
                  rules={{ required: true }}
                  control={changeUserProfileForm.control}
                  defaultValue={""}
                  name="phone_number"
                  render={({ field }) => (
                    <InputText
                      className={classNames({
                        "p-invalid": changeUserProfileForm.formState.errors.phone_number,
                      })}
                      ref={field.ref}
                      id="phone_number"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="input phone number"
                    />
                  )}
                />
                {changeUserProfileForm.formState.errors.phone_number && (
                  <small id="phone_number" className="p-error block pt-1">
                    {changeUserProfileForm.formState.errors.phone_number.message}
                  </small>
                )}
              </div>
              <div className="flex justify-content-center mt-5 col-12 gap-1">
                <div className="mr-4">
                  <Button disabled={!permissions.update} loading={loadingChangeDetails} label="Save Changes" className="p-button-success " />
                </div>
                <div className="">
                  <Link to="/dashboard/products">
                    <Button type="button" label="Back To Dashboard" className=" p-button-secondary" />
                  </Link>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel header="Change Password">
            <form onSubmit={changePasswordForm.handleSubmit(changePasswordHandler)} className="p-fluid formgrid grid">
              {/* form */}
              <div className="field col-12 md:col-12">
                <label htmlFor="current_password">Current Password :</label>
                <Controller
                  rules={{ required: true }}
                  control={changePasswordForm.control}
                  defaultValue={""}
                  name="current_password"
                  render={({ field }) => (
                    <Password
                      inputClassName={classNames({
                        "p-invalid": changePasswordForm.formState.errors.current_password,
                      })}
                      ref={field.ref}
                      id="current_password"
                      value={field.value}
                      toggleMask
                      feedback={false}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Input current password"
                    />
                  )}
                />
                {changePasswordForm.formState.errors.current_password && (
                  <small id="current_password" className="p-error block pt-1">
                    {changePasswordForm.formState.errors.current_password.message}
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="password">New Password :</label>
                <Controller
                  rules={{ required: true }}
                  control={changePasswordForm.control}
                  defaultValue={""}
                  name="password"
                  render={({ field }) => (
                    <Password
                      ref={field.ref}
                      inputClassName={classNames({
                        "p-invalid": changePasswordForm.formState.errors.password,
                      })}
                      toggleMask
                      id="password"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Input new password"
                    />
                  )}
                />
                {changePasswordForm.formState.errors.password && (
                  <small id="password" className="p-error block pt-1">
                    {changePasswordForm.formState.errors.password.message}
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="confirm_password">Confirm New Password :</label>
                <Controller
                  rules={{ required: true }}
                  control={changePasswordForm.control}
                  defaultValue={""}
                  name="confirm_password"
                  render={({ field }) => (
                    <Password
                      inputClassName={classNames({
                        "p-invalid": changePasswordForm.formState.errors.confirm_password,
                      })}
                      feedback={false}
                      toggleMask
                      ref={field.ref}
                      id="confirm_password"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Input new password"
                    />
                  )}
                />
                {changePasswordForm.formState.errors.confirm_password && (
                  <small id="confirm_password" className="p-error block pt-1">
                    {changePasswordForm.formState.errors.confirm_password.message}
                  </small>
                )}
              </div>
              <div className="flex justify-content-center mt-5 col-12 gap-1">
                <div className="mr-4">
                  <Button disabled={!permissions.update} loading={loadingChangePassword} label="Change Password" className="p-button-success " />
                </div>
                <div className="">
                  <Link to="/dashboard/products">
                    <Button type="button" label="Back To Dashboard" className=" p-button-secondary" />
                  </Link>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default AccountSetting;
