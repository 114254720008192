// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import toast from "react-hot-toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

// api related
import Api from "../../api/Api";
import { InputTextarea } from "primereact/inputtextarea";

const typeOption = [
  { label: "TEXT", value: 0 },
  { label: "IMAGE", value: 1 },
];
// const groupType = [
//   { label: "SKIN", value: 0 },
//   { label: "HAIR", value: 1 },
// ];

const QuizUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const { control, handleSubmit, watch, reset } = useForm();
  const is_multiple_choice_watch = watch("is_multiple");

  // query
  const { isLoading: quizOptionDataIsLoading, data: quizOptionData } = useQuery({
    queryKey: "quiz-options",
    queryFn: async () => {
      try {
        const res = await Api().get("/quiz-choice?page=1&limit=999999&sort_by=id&sort_type=DESC");

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/quiz/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        toast.success("Quiz Updated!", { duration: 4000 });
        navigate("/dashboard/quiz");
      } else {
        toast.error(response.data.err, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    data.choices = data.choices.join(",");
    data.is_multiple = data.is_multiple ? 1 : 0;
    data.show_in_profile = data.show_in_profile ? 1 : 0;
    updateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Quiz
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const itemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        {option.image ? (
          <img width={50} alt={option.image} src={option?.image} />
        ) : (
          <div className="text-center" style={{ width: 50 }}>
            -
          </div>
        )}
        <div className="ml-2">{option.text}</div>
      </div>
    );
  };

  // lifecycle
  useEffect(() => {
    const getQuizDetails = async () => {
      try {
        const response = await Api().get("/quiz/detail/" + id);

        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        let data = response.data.data;

        if (data?.choices) {
          data.choices = data.choices.split(",").map((d) => +d);
        }

        data.is_multiple = data?.is_multiple === 1 ? true : false;
        data.show_in_profile = data.show_in_profile === 1 ? true : false;

        reset(data);
      } catch (error) {
        toast.error(error);
      }
    };
    if (id && !quizOptionDataIsLoading) {
      getQuizDetails();
    }
  }, [id, quizOptionDataIsLoading, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12">
          <label htmlFor="text">Title</label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="title" render={({ field }) => <InputTextarea placeholder="Input title" id="text" type="text" className="w-full" rows={5} value={field.value} onChange={(e) => field.onChange(e)} />} />
          </div>
        </div>
        {/* <div className="field col-12">
          <label htmlFor="group_type">Group Type : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="group_type"
              render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="group_type" className="w-full" value={field.value} onChange={(e) => field.onChange(e)} options={groupType} placeholder="Select Type" />}
            />
          </div>
        </div> */}

        <div className="field col-12">
          <label htmlFor="text">Question</label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="question" render={({ field }) => <InputTextarea placeholder="Input question" id="text" type="text" className="w-full" rows={5} value={field.value} onChange={(e) => field.onChange(e)} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="type">Card Type : </label>
          <div className="w-full">
            <Controller rules={{ required: true }} control={control} name="type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="type" value={field.value} className="w-full" onChange={(e) => field.onChange(e)} options={typeOption} placeholder="Select Type" />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="choices">Options : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="choices"
              render={({ field }) => (
                <MultiSelect
                  filter
                  className="w-full"
                  itemTemplate={itemTemplate}
                  emptyFilterMessage="no data found"
                  disabled={quizOptionDataIsLoading}
                  value={field.value}
                  options={quizOptionData}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="text"
                  optionValue="id"
                  placeholder="Select choices"
                  display="chip"
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="order">Order :</label>
          <div className="w-full">
            <Controller defaultValue="" control={control} name="order" render={({ field }) => <InputNumber min={0} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} className="w-full" showButtons mode="decimal" placeholder="0" />} />
          </div>
        </div>
        <div className="field col-12">
          <label className="block" htmlFor="is_multiple">
            Multiple Choice
          </label>
          <Controller control={control} name="is_multiple" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
        </div>
        {is_multiple_choice_watch ? (
          <div className="field col-12">
            <label htmlFor="max_choice">Max Choice :</label>
            <div className="w-full">
              <Controller
                defaultValue={1}
                control={control}
                name="max_choice"
                render={({ field }) => <InputNumber disabled={!is_multiple_choice_watch} min={1} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} className="w-full" showButtons mode="decimal" placeholder="0" />}
              />
            </div>
          </div>
        ) : null}

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/quiz">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(QuizUpdate, comparisonFn);
